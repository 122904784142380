<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valueIndex) in values" :key="valueIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-card :title="$t('Guide.Phone Guide')">
      <b-row>
        <!-- sorting -->
        <b-col md="2" class="my-1">
          <b-form-group
            label="الماركة"
            label-cols-sm="4"
            label-align-sm="right"
            label-size="sm"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-model="brand"
              class="border mr-0"
              size="sm"
              :text="brandName"
              variant="flat-primary"
            >
              <b-dropdown-item @click="changeStatus('الكل', 'all')">
                الكل
              </b-dropdown-item>
              <b-dropdown-item
                @click="changeStatus(item.name, item.id)"
                v-for="(item, index) in brands"
                :key="index"
              >
                {{ item.name }}
                <b-badge pill variant="primary" class="float-right">{{
                  item.order_counts
                }}</b-badge>
              </b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
        <!-- add new brand -->

        <!-- start data table -->
        <b-col cols="12">
          <b-table
            id="ItemTable"
            ref="admint"
            striped
            hover
            responsive
            show-empty
            :busy.sync="isBusy"
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(cover)="data"
              ><b-img
                style="height: 100px; width:auto"
                class="mx-auto"
                :src="data.value"
            /></template>
            <template #cell(brand_logo)="data" 
              ><b-img
                :src="data.value"
                width="auto"
                height="20px"
                class="mx-auto"
            /></template>
            <template #cell(actions)="items">
              <div>
                <!-- show button item -->
                <b-button
                  style="padding:7px; margin:6px; width:35px; height:35px;"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/ShowPhoneGuide/' + items.item.id }"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon
                    style="width:18px; height:18px;"
                    icon="EyeIcon"
                    class="align-middle"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-card-body
        class="d-flex justify-content-between p-0 pagination-section"
      >
        <!-- page length -->
        <div class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("Product_Index.Per_Page")
          }}</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="CategoryTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: "created_at",
      sortDesc: true,
      sortDirection: "desc",
      brand: "all",
      brandName: this.$t("Table_Head.All"),
      brands: [],
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      // table fields
      fields: [
        {
          key: "cover",
          label: this.$t("Table_Head.Cover"),
        },
        {
          key: "name",
          label: this.$t("Table_Head.Name"),
        },
        {
          key: "brand_logo",
          label: this.$t("Table_Head.Brand"),
        },

        {
          key: "purchse_date",
          label: this.$t("Table_Head.Purchase_Date"),
          sort_by: true,
        },

        {
          key: "actions",
          label: this.$t("Table_Head.Actions"),
        },
      ],
      items: [],
      exportItems: [],
    };
  },
  created() {
    this.fetchData();
    axios
      .get("brands/index")
      .then((result) => {
        const data = result.data.data;
        this.brands = data;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sort_by)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortDirection: function() {
      this.fetchData();
    },
    sortBy: function() {
      this.fetchData();
    },
    filter: function() {
      if (this.filter == "" || this.filter == null) {
        this.fetchData();
      }
    },
    brand: function() {
      this.fetchData();
    },
  },
  methods: {
    // fetch data of items
    fetchData() {
      this.showDismissibleAlert = false;
      // request phones guide index API
      var url = null;
      if (this.filter == "") {
        url =
          "items/" +
          this.perPage.toString() +
          "/" +
          this.brand +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "?page=" +
          this.currentPage;
      } else {
        url =
          "items/" +
          this.perPage.toString() +
          "/" +
          this.brand +
          "/sortBy-" +
          this.sortBy +
          "-" +
          this.sortDirection +
          "/" +
          this.filter +
          "?page=" +
          this.currentPage;
      }
      const promise = axios.get(url);
      return promise
        .then((result) => {
          const data = result.data.data.data;
          this.totalRows = result.data.data.meta.total;
          this.items.length = 0;

          this.items = data;
          this.exportItems = this.items;

          this.$refs.admint.refresh();
          return this.items;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    // delete function to delete item
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete item.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("items-guide/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    changeStatus(name, id) {
      this.brand = id;
      this.brandName = name;
    },
    removeExitem() {
      for (let ex in this.exportItems) {
        delete this.exportItems[ex].cover;
      }
    },
    genPDF() {
                const { jsPDF } = require("jspdf");
      const doc = new jsPDF();
      let print_body = [];
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].active,
          this.items[index].created_at,
          this.items[index].updated_at,
        ];
      }
      doc.autoTable({
        head: [["ID", "Name", "Active", "CREATED_AT", "UPDATED_AT"]],
        body: print_body,
      });
      doc.save("table.pdf");
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style>
@media only screen and (max-width: 425px) {
  .pagination-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pagination-section div {
    margin: 10px;
  }
  .dropdown-menu {
    max-height: 300px;
    overflow: scroll;
  }
}
</style>
